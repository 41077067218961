import type { UserContextProps, UserProviderProps } from './contracts';
import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { MomentInput } from 'moment-timezone';
import TZ from 'moment-timezone';
import { ActionType } from './contracts';
import { initialState, userReducer } from './reducers';
import 'moment/dist/locale/pt-br';
import 'moment/dist/locale/es';
import { LoadingFallbackComponent } from '../../components/LoadingFallback/LoadingFallbackComponent';
import { TIMEZONES } from '../../../../constants';
import { useThemeContext } from '../theme';
import type { AvailableLanguages } from 'modules/core/entities/Administrator/Administrator.entity';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { Mixpanel } from 'utilities';

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

export const useUserContext = (): UserContextProps => useContext(UserContext);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const [loading, setLoading] = useState(true);

  const { changeTheme } = useThemeContext();

  const { i18n } = useTranslation();

  const lokiService = useMemo(() => new ServicePulsusLoki(), []);

  const changeUserLanguage = (lang: AvailableLanguages) => {
    i18n.changeLanguage(lang);

    moment.locale(lang);

    document.querySelector('html')?.setAttribute('lang', lang);
  };

  const getAdminInfos = async () => {
    const administrator = await lokiService.getAdminInfos();

    changeUserLanguage(administrator.language);

    const timezone = TIMEZONES[administrator.timezone] || TIMEZONES.Brasilia;
    const offset = moment(TZ.utc(moment() as MomentInput).tz(timezone)).utcOffset();

    dispatch({
      type: ActionType.UPDATE_ADMINISTRATOR_INFOS,
      payload: { ...administrator, timezone, offset },
    });
  };

  const getTenantInfos = async () => {
    const tenant = await lokiService.getTenantsInfo();

    changeTheme(tenant.theme);
    changeTabTitle(tenant.name);

    dispatch({ type: ActionType.UPDATE_TENANT_INFOS, payload: tenant });
  };

  const updateLogo = (newUrl: string) => {
    dispatch({ type: ActionType.UPDATE_LOGO, payload: newUrl });
  };

  const changeTabTitle = (title: string) => {
    document.title = title;
  };

  const getFeatureFlags = async () => {
    const featureFlags = await lokiService.getFeatureFlags();
    dispatch({ type: ActionType.UPDATE_FEATURE_FLAGS, payload: featureFlags });
  };

  const fetchInfos = async () => {
    try {
      setLoading(true);
      await Promise.all([getAdminInfos(), getTenantInfos(), getFeatureFlags()]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfos();
  }, []);

  useEffect(() => {
    if (!loading) {
      Mixpanel.identify(state.administrator.email);
      Mixpanel.people.set({
        $name: state.administrator.name || state.administrator.email,
        $email: state.administrator.email,
        administrator_id: state.administrator.id,
        tenant: state.tenant?.name,
        tenant_id: state.tenant?.id,
      });
    }
  }, [loading]);

  const contextValue = useMemo(
    () => ({
      administrator: state.administrator,
      tenant: state.tenant,
      featureFlags: state.featureFlags,
      updateLogo,
    }),
    [state.administrator, state.tenant, state.featureFlags, updateLogo]
  );

  return <UserContext.Provider value={contextValue}>{loading ? <LoadingFallbackComponent /> : children}</UserContext.Provider>;
};
