import { EuiButtonEmpty, EuiPagination } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { StyleProps } from './PageDevices.contracts';

export const Main = styled.main``;

export const Container = styled.main`
  ${({ theme }) => css`
    background-color: ${ConstantColors.BRAZIL};
    position: relative;

    a {
      &:hover {
        transition: 0.3s;
        transform: translateY(-2px);
      }
    }

    .link {
      color: ${ConstantColors.CHILE};
      font-weight: 500;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        text-decoration: underline;
        transition: 0.3s;
        transform: translateY(-2px);
      }
    }

    .theme-color {
      color: ${theme.main};
    }

    .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
      border-color: ${theme.main} !important;
      background-color: ${theme.main} !important;

      @keyframes focusRingAnimate {
        0% {
          box-shadow: 0 0 0 6px ${theme.main}00;
        }
        100% {
          box-shadow: 0 0 0 3px ${theme.main}60;
        }
      }
    }
  `}
`;

export const TopContainer = styled.section`
  padding: 0 30px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${ConstantColors.AUSTRIA};
`;

export const TopLeft = styled.div`
  width: 35%;
  min-width: 400px;
  max-width: 404px;

  h2 {
    color: ${ConstantColors.ARGENTINA};
  }

  p {
    font-size: 12px;
  }

  .euiBadge__text {
    font-size: 11px;
    cursor: pointer;
  }

  .euiButtonEmpty {
    span {
      padding: 0 2px;
    }
    &--primary:focus {
      background-color: transparent;
    }
  }

  .number-devices {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }

  .status-item {
    display: flex;
    align-items: center;
    margin-left: 0px;

    p {
      margin-left: -2px;
    }
  }

  .title {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .small {
    color: ${ConstantColors.INDIA};
    font-size: 12px;
    max-width: 170px;
  }

  .link {
    margin-top: 15px;
    font-size: 14px;
    white-space: nowrap;
  }
`;

export const SearchBar = styled.div<StyleProps>`
  ${({ isDisabled }) => css`
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: ${isDisabled ? 0.4 : 1};
    pointer-events: ${isDisabled ? 'none' : 'unset'};
    max-width: 410px;

    .text-field {
      display: flex;
    }

    .euiFieldSearch {
      max-width: 220px;
      &::placeholder {
        font-size: 11px;
      }
    }
  `}
`;

export const SearchSelect = styled.select`
  height: 40.5px;
  padding: 12px;
  margin-right: -5px;
  background-color: #e9edf3;
  color: #1a1c21;
  font-weight: 600;
  font-size: 12px;
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 1;
`;

export const SearchAppend = styled.div`
  position: absolute;
  right: 9px;

  display: flex;
  justify-content: center;

  width: 42px;
  height: 41px;

  background-color: #e9edf3;
  border: 2px solid #e9edf3;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const SearchButtonIcon = styled(EuiButtonEmpty)`
  color: #4e4b49;
`;

export const Pagination = styled(EuiPagination)`
  background-color: ${ConstantColors.AUSTRIA};
  position: absolute;
  bottom: 0px;
  right: 30px;
`;

export const DevicesPerPage = styled.div`
  background-color: ${ConstantColors.AUSTRIA};
  font-size: 14px;
  position: absolute;
  bottom: 7px;
  left: 30px;
`;

export const TopRight = styled.div<StyleProps>`
  ${({ isDisabled }) => css`
    opacity: ${isDisabled ? 0.4 : 1};
    pointer-events: ${isDisabled ? 'none' : 'unset'};
    max-width: 710px;
    min-width: 710px;
    background-color: ${ConstantColors.BRAZIL};
    padding: 25px 15px 5px 15px;
    border-radius: 10px;

    .title {
      font-weight: 500;
      color: ${ConstantColors.HAITI};
    }

    .grid {
      position: relative;
      display: grid;
      align-items: center;
      grid-template-columns: 25px repeat(4, auto);
      grid-template-rows: 70px 70px;
      transition: 0.3s;
      cursor: pointer;

      svg {
        &:hover {
          transition: 0.3s;
          transform: translateY(-2px);
        }
      }
    }
  `}
`;

export const Panel = styled.div`
  display: grid;
  grid-template-columns: auto;
`;

export const PanelRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 25px auto;
  padding: 15px 0;
  overflow: hidden;
`;

export const RowIcon = styled.div`
  display: flex;
  justify-content: center;
  svg,
  img {
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      transform: translateY(-2px);
    }
  }
`;

export const RowContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 150px 160px 150px 200px;
`;

export const ManagementiOSLabel = styled.div<{ macOS?: boolean }>`
  ${({ macOS }) => css`
    position: absolute;
    top: -17px;
    left: ${macOS ? '310px' : '10px'};

    display: flex;
    align-items: center;

    p {
      color: #5a606b;
    }

    .line {
      width: ${macOS ? '35px' : '100px'};
      height: 5px;
      border-top: 2px solid #dfdfdf;
      border-left: 2px solid #dfdfdf;
      margin: 5px 9px 0px;

      &.inverted {
        border-right: 2px solid #dfdfdf;
        border-left: unset;
      }
    }
  `}
`;

export const TableBox = styled.div`
  position: relative;
  padding: 30px 25px 35px;
  background-color: #ffffff;

  .euiTableHeaderCell {
    .euiTableCellContent__text {
      font-weight: 500;
      white-space: break-spaces;
    }
  }

  .euiTableCellContent {
    min-height: 40px;

    &__text:not(.battery-text) {
      font-size: 12px;
      color: #67758d;
      width: 100%;
    }
  }

  .euiLoadingContent__singleLine {
    height: 40px;

    &:last-child {
      width: 100%;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  margin-left: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

export const Selected = styled.div<StyleProps>`
  ${({ isAllDevicesSelected }) => css`
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-end;
    font-size: 12px;
    font-weight: 700;
    color: #67758d;

    ${isAllDevicesSelected
      ? css`
          p {
            position: absolute;
            top: -20px;
          }

          button:first-of-type {
            margin-left: -8px;
          }

          button:last-of-type {
            color: #ff5454;
            margin-left: 10px;
          }
        `
      : css`
          button {
            color: #ff5454 !important;
            margin-left: 10px;
          }
        `}
  `}
`;

export const AdvancedSearchContainer = styled.section`
  overflow: scroll;
  &.closed {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s ease-out;
  }

  &.open {
    max-height: 800px;
    opacity: 1;
    transition: 0.2s ease-in-out;
  }
`;

export const IdentifierColumn = styled.div`
  display: flex;

  .edit-icon-button {
    opacity: 0.2;
    padding: 5px;

    &:hover {
      opacity: 1;
    }
  }
`;

export const IdentifierLink = styled.a`
  display: flex;
  align-items: center;

  .id {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 5px;
    padding: 3px 7px;
    border: 0.6px solid ${ConstantColors.PORTUGAL};
    border-radius: 8px;
    background-color: ${ConstantColors.AUSTRIA};
    font-weight: 700 !important;
    height: 16px;
    min-width: 60px;

    font-size: 11px;
    text-decoration: underline;
  }
`;

export const DeviceIcon = styled.div`
  position: relative;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-warning {
    position: absolute;
    top: -1px;
    right: -5px;
  }
`;

export const StatusColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const ManagementColumn = styled.div<StyleProps>`
  ${({ color }) => css`
    width: 120px;
    height: 18px;
    color: #ffffff;
    background-color: ${color || 'unset'};
    border-radius: 3px;

    p {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
  `}
`;

export const InfoColumn = styled.div`
  div {
    line-height: 12px;
    color: #67758d;
    font-size: 10px;
    font-weight: 500;
  }
`;

export const GroupColumn = styled.button`
  display: flex;
  align-items: center;

  svg {
    min-width: 15px;
  }

  div {
    font-size: 12px;
    text-decoration: underline;
    margin-left: 8px;
    line-height: 15px;
    font-weight: 500;
    text-align: left;

    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    max-height: 45px;
  }
`;

export const LastSyncColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const BatteryColumn = styled.div`
  p {
    min-width: 25px;
    color: #67758d;
    font-size: 12px;
    white-space: nowrap;
  }

  div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .battery-temp {
    margin-left: 15px;

    p {
      margin-left: -3px;
    }
  }

  .battery-undefined {
    margin-left: 30px;
  }
`;

export const ActionsColumn = styled.div`
  display: flex;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: ${ConstantColors.IRAQ};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: ${ConstantColors.BRUNEI};
      transition: 0.3s;
    }
  }
`;

export const PngIcon = styled.img`
  width: 30px;
`;

export const ManagementIconOS = styled.img`
  width: 22px;
`;

export const AndroidIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 50%;

  img {
    width: 20px;
  }
`;

export const IconButton = styled.button<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    opacity: ${disabled ? 0.4 : 1};
    pointer-events: ${disabled ? 'none' : 'unset'};
    cursor: ${disabled ? 'unset' : 'pointer'};
  `}
`;
