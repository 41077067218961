import * as S from './AddEmployeeUserModalComponent.styles';
import { ComponentButton, ComponentEmptyButton } from 'modules/core/components';
import { IconEmployeeUsers } from 'icons/employee-users/icon-employee-users';
import { useEffect, useMemo, useState } from 'react';
import type { EmployeeUser } from 'modules/operational/entities/EmployeeUser/EmployeeUser.entity';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { UploadCsvModalComponent } from 'components/upload-csv-modal/UploadCsvModalComponent';
import { EuiFieldPassword, EuiFieldText, EuiFormRow, EuiIcon, EuiTab, EuiToolTip } from '@elastic/eui';
import type { AddEmployeeUserModalComponentProps } from './AddEmployeeUserModalComponent.contracts';
import axios from 'axios';
import { useTypedTranslation } from 'modules/core/hooks';
import { GetValidationError } from 'modules/core/utilities';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';

const defaultProps = {
  isEmbedded: false,
};

export const AddEmployeeUserModalComponent = ({
  isEmbedded,
  handleCloseModal,
  handleError,
  handleSuccess,
  emitChange,
  userManagementData,
}: AddEmployeeUserModalComponentProps) => {
  const sampleHref = '/import_user_sample.csv';

  const [employeeUserData, setEmployeeUserData] = useState<EmployeeUser>({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    status: 1,
    email: '',
    employeeId: '',
    workPhone: '',
    homePhone: '',
    managerName: '',
    managerEmail: '',
    managerPhoneNumber: '',
    costCenter: '',
    employeeTitle: '',
    department: '',
  });

  const [modalStep, setModalStep] = useState<string>('createUser');
  const [selectedTab, setSelectedTab] = useState<string>('PERSONAL');

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    workPhone: false,
    personalPhone: false,
    phoneManager: false,
  });

  useEffect(() => {
    if (userManagementData) {
      setEmployeeUserData({ ...userManagementData });
    }
  }, [userManagementData]);

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const { t } = useTypedTranslation<'users.addEmployee'>('users.addEmployee');

  const handleChange = (key: keyof typeof employeeUserData, value: string) => {
    const regex = /\d/;

    setErrors((prevErrors) => ({ ...prevErrors, [key]: regex.test(value) || value === '' }));
    setEmployeeUserData({ ...employeeUserData, [key]: value });
  };

  const handleChangePhone = (key: keyof typeof employeeUserData, value: string) => {
    const reg = /^\d+$/;

    setErrors({ ...errors, [key]: value === '' ? false : !reg.test(value) });
    setEmployeeUserData({ ...employeeUserData, [key]: value });
  };

  const canSubmit =
    Object.values(errors).some((value) => value === true) ||
    !employeeUserData.firstName ||
    !employeeUserData.lastName ||
    !employeeUserData.username ||
    (!userManagementData && !employeeUserData.password);

  const renderTooltipPassword = () => {
    if (!userManagementData) {
      return requiredLabel(t('form.personal.password.label'));
    }

    return (
      <S.TooltipContainer>
        {t('form.personal.password.label')}
        <EuiToolTip content={t('form.personal.password.tooltip')} position="right">
          <EuiIcon type="questionInCircle" color=" #08090A;" />
        </EuiToolTip>
      </S.TooltipContainer>
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (userManagementData) {
        await serviceLoki.updateEmployeeUser(employeeUserData);
      } else {
        await serviceLoki.createEmployeeUser(employeeUserData);
      }

      handleSuccess([
        {
          color: 'success',
          iconType: 'check',
          id: '1',
          title: `${t('sucess_create')}`,
          text: '',
        },
      ]);
      emitChange && emitChange();

      handleCloseModal();
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        if (GetValidationError(err?.response?.data, 'email') || GetValidationError(err?.response?.data, 'managerEmail')) {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '3',
              title: `${t('invalid_email')}`,
              text: `${t('error_create')}`,
            },
          ]);
        }

        const errorDetail = err?.response?.data?.detail || err.message;

        if (errorDetail === 'User already exists') {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '2',
              title: `${t('error_duplicate_user_form')}`,
              text: `${t('error_create')}`,
            },
          ]);
        }
      } else {
        handleError([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '2',
            title: `${t('error_create')}`,
            text: '',
          },
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (file) => {
    try {
      const data = await serviceLoki.importEmployeeUsers(file[0]);

      if (data.data === 'Users created successfully') {
        handleCloseModal();
        emitChange && emitChange();
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        const errorDetail = err?.response?.data?.detail || err.message;

        if (errorDetail === 'Users could not be created') {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '2',
              title: `${t('error_duplicate_user_file')}`,
              text: '',
            },
          ]);
        }
      } else {
        handleError([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '2',
            title: `${t('error_create')}`,
            text: '',
          },
        ]);
      }
    }
  };

  const requiredLabel = (label) => {
    return (
      <>
        {label} <S.Required>*</S.Required>
      </>
    );
  };

  const tabs = [
    {
      id: 'PERSONAL',
      name: t('tabs.personal'),
      content: (
        <S.FormDivision editUser={!!userManagementData}>
          <EuiFormRow
            fullWidth
            label={
              <S.TooltipContainer>
                {t('form.personal.username.label')}
                <S.Required>*</S.Required>
                <EuiToolTip content={t('form.personal.username.tooltip')} position="right">
                  <EuiIcon type="questionInCircle" color=" #08090A;" />
                </EuiToolTip>
              </S.TooltipContainer>
            }
          >
            <EuiFieldText
              name="username"
              value={employeeUserData.username}
              placeholder={t('form.personal.username.placeholder')}
              onChange={(e) => handleChange('username', e.target.value)}
              fullWidth
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label={requiredLabel(t('form.personal.firstName.label'))}
            isInvalid={errors.firstName}
            error={t('form.error.onlyLetters')}
          >
            <EuiFieldText
              value={employeeUserData.firstName}
              placeholder={t('form.personal.firstName.placeholder')}
              onChange={(e) => handleChange('firstName', e.target.value)}
              fullWidth
            />
          </EuiFormRow>
          <EuiFormRow
            fullWidth
            label={requiredLabel(t('form.personal.lastName.label'))}
            isInvalid={errors.lastName}
            error={t('form.error.onlyLetters')}
          >
            <EuiFieldText
              value={employeeUserData.lastName}
              placeholder={t('form.personal.lastName.placeholder')}
              onChange={(e) => handleChange('lastName', e.target.value)}
              fullWidth
            />
          </EuiFormRow>

          <ConditionalSlotComponent renderIf={!userManagementData}>
            <EuiFormRow fullWidth label={renderTooltipPassword()}>
              <EuiFieldPassword
                value={employeeUserData.password}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, password: e.target.value })}
                placeholder={t('form.personal.password.placeholder')}
                fullWidth
                type="dual"
              />
            </EuiFormRow>
          </ConditionalSlotComponent>
        </S.FormDivision>
      ),
    },

    {
      id: 'ORGANIZATIONAL',
      name: t('tabs.organizational'),
      content: (
        <S.FormDivision editUser={!!userManagementData}>
          <EuiFormRow fullWidth label={t('form.organizational.registration.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.registration.placeholder')}
              name="registration"
              value={employeeUserData.employeeId}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, employeeId: e.target.value })}
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={errors.workPhone}
            error={t('form.error.onlyNumbers')}
            fullWidth
            label={
              <S.TooltipContainer>
                {t('form.organizational.workPhone.label')}
                <EuiToolTip content={t('form.organizational.phoneTooltip')} position="right">
                  <EuiIcon type="questionInCircle" color=" #08090A;" />
                </EuiToolTip>
              </S.TooltipContainer>
            }
          >
            <EuiFieldText
              placeholder={t('form.organizational.workPhone.placeholder')}
              name="workPhone"
              value={employeeUserData.workPhone}
              onChange={(e) => handleChangePhone('workPhone', e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={errors.personalPhone}
            error={t('form.error.onlyNumbers')}
            fullWidth
            label={
              <S.TooltipContainer>
                {t('form.organizational.personalPhone.label')}
                <EuiToolTip content={t('form.organizational.phoneTooltip')} position="right">
                  <EuiIcon type="questionInCircle" color=" #08090A;" />
                </EuiToolTip>
              </S.TooltipContainer>
            }
          >
            <EuiFieldText
              placeholder={t('form.organizational.personalPhone.placeholder')}
              name="personalPhone"
              value={employeeUserData.homePhone}
              onChange={(e) => handleChangePhone('homePhone', e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.email.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.email.placeholder')}
              value={employeeUserData.email}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, email: e.target.value })}
              name="email"
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.managerName.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.managerName.placeholder')}
              value={employeeUserData.managerName}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, managerName: e.target.value })}
              name="managerName"
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.managerEmail.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.managerEmail.placeholder')}
              value={employeeUserData.managerEmail}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, managerEmail: e.target.value })}
              name="managerEmail"
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={errors.phoneManager}
            error={t('form.error.onlyNumbers')}
            fullWidth
            label={
              <S.TooltipContainer>
                {t('form.organizational.phoneManager.label')}
                <EuiToolTip content={t('form.organizational.phoneTooltip')} position="right">
                  <EuiIcon type="questionInCircle" color=" #08090A" />
                </EuiToolTip>
              </S.TooltipContainer>
            }
          >
            <EuiFieldText
              placeholder={t('form.organizational.phoneManager.placeholder')}
              name="phoneManager"
              value={employeeUserData.managerPhoneNumber}
              onChange={(e) => handleChangePhone('managerPhoneNumber', e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.costCenter.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.costCenter.placeholder')}
              name="costCenter"
              value={employeeUserData.costCenter}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, costCenter: e.target.value })}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.employeeTitle.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.employeeTitle.placeholder')}
              name="employeeTitle"
              value={employeeUserData.employeeTitle}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, employeeTitle: e.target.value })}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label={t('form.organizational.department.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.department.placeholder')}
              name="department"
              value={employeeUserData.department}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, department: e.target.value })}
            />
          </EuiFormRow>
        </S.FormDivision>
      ),
    },
  ];

  const handleChangeStep = () => {
    setModalStep('createUser');
  };

  const renderContent = () => {
    const contentTypes = {
      createUser: renderUserContent(),
      uploadCsv: renderUploadCsvContent(),
    };

    return contentTypes[modalStep];
  };

  const renderUserContent = () => {
    return (
      <>
        <S.Header>
          <S.Title>
            <S.Icon>
              <IconEmployeeUsers />
            </S.Icon>
            <p>{!userManagementData ? t('title') : t('editTitle')}</p>
          </S.Title>
          <S.UploadFile className="breadcrumbText" onClick={() => setModalStep('uploadCsv')}>
            <p>{t('upload_button')}</p>
          </S.UploadFile>
        </S.Header>

        <S.Tabs>
          {tabs.map((tab) => (
            <EuiTab key={tab.id} onClick={() => setSelectedTab(tab.id)} isSelected={tab.id === selectedTab}>
              {tab.name}
            </EuiTab>
          ))}
        </S.Tabs>

        <S.Body>
          <form onSubmit={handleSubmit}>{tabs.find((obj) => obj.id === selectedTab)?.content}</form>
        </S.Body>
        <S.Footer>
          <ComponentEmptyButton label={t('cancel_button')} type="primary" onClick={handleCloseModal} />
          <ComponentButton loading={loading} label={t('submit_button')} onClick={handleSubmit} isDisabled={canSubmit} />
        </S.Footer>
      </>
    );
  };

  const renderUploadCsvContent = () => {
    return (
      <UploadCsvModalComponent
        onClose={() => handleCloseModal()}
        changeStep={handleChangeStep}
        uploadFile={handleUploadFile}
        sampleHref={sampleHref}
        emitChange={emitChange}
      />
    );
  };

  return (
    <>
      {modalStep === 'createUser' && <S.CreateUserContent isEmbedded={isEmbedded}>{renderContent()}</S.CreateUserContent>}

      {modalStep === 'uploadCsv' && <S.UploadFileContent isEmbedded={isEmbedded}>{renderContent()}</S.UploadFileContent>}
    </>
  );
};

AddEmployeeUserModalComponent.defaultProps = defaultProps;
