import { Sim } from 'modules/management/entities';
import type { DistributionParams, FilterOptions } from 'modules/operational/pages/apps/PageApps.contracts';
import type { APIApps, APIDistributionCounter } from 'modules/operational/entities/app.entity';
import { Group } from 'entities/group';
import type { EditDeviceAPIBody } from 'modules/operational/components/devices/EditDeviceModal/EditDeviceModalComponent.contracts';
import { Tag } from 'modules/operational/entities';
import { randomId } from 'modules/core/utilities';
import { type APIDeviceDetails, type APIGroups } from 'modules/operational/entities/devices';
import { BaseServiceInstance } from './BaseServiceInstance';
import { ChromeReportGroup } from 'entities/ChromeReportGroup';
import { AxiosError } from 'axios';
import type { BulkActions, SelectAllBulkActionsBody } from 'modules/operational/pages/devices/PageDevices.contracts';
import { DeviceHistory } from 'modules/operational/entities/DeviceHistory/DeviceHistory.entity';
import { Location } from 'modules/operational/entities/Location/Location.entity';
import { LocationDetails } from 'modules/operational/entities/LocationDetails/LocationDetails.entity';
import type { Pageable } from '../../types/utils';
import { DeviceMapList } from 'modules/operational/entities/DeviceMapList/DeviceMapList.entity';
import { GroupList } from 'modules/operational/entities/GroupList/GroupList.entity';
import { Administrator } from 'modules/core/entities/Administrator/Administrator.entity';
import { Tenant } from 'modules/core/entities/Tenant/Tenant.entity';
import { ChromePolicies } from 'modules/chromeOS/entities/ChromePolicies/ChromePolicies.entity';
import { EmployeeUser } from 'modules/operational/entities/EmployeeUser/EmployeeUser.entity';
import type { KioskProps } from 'modules/chromeOS/components/PoliciesModal/KioskMode/KioskModeComponent.contracts';
import { Device } from 'modules/core/entities/Device/Device.entity';

export class ServicePulsusLoki extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_URL_PULSUS_LOKI_API!);
  }

  async getDeviceDetailsById(device_id: string): Promise<APIDeviceDetails> {
    const { data } = await this._instance.get(`/device/${device_id}/complete`, {
      params: {
        device_id,
      },
    });
    return data;
  }

  async getDevices(actualPage: number) {
    const params = {
      $filter: 'status ne 3 and status ne 2 and status ne 8 and status ne 9',
    };
    const { data } = await this._instance.get(`/device/page/${actualPage + 1}`, { params });

    return data;
  }

  async getFilteredDevices(filter: string, actualPage: number) {
    const { data } = await this._instance.get(`/device/page/${actualPage + 1}${filter}`);
    return data;
  }

  async getSearchedDevices(filter_by: string, searchValue: string, actualPage: number, custom_field_id?: string) {
    const params = {
      filter_by,
      custom_field_id,
      search_str: searchValue,
    };

    const { data } = await this._instance.get(`/device/page/${actualPage + 1}/basic_filter`, { params });
    return data;
  }

  async getNumberOfDevicesPerManagement() {
    const { data } = await this._instance.get('/device/count_by_management');
    return data;
  }

  async getNumberOfDevicesPerStatus() {
    const { data } = await this._instance.get('/device/count_by_status');
    return data;
  }

  async getVersions() {
    const { data } = await this._instance.get('/device/os_versions_unique');
    return data;
  }

  async batchEditGroups(groupId: number, device_ids: string[]) {
    const body = {
      device_ids,
    };

    const { data } = await this._instance.patch(`/device/bulk/update_group/${groupId}`, body);
    return data;
  }

  async batchSendMessage(adm_message: string, device_ids: string[]) {
    const body = {
      device_ids,
      adm_message,
    };

    const { data } = await this._instance.post('/device/bulk/send_message', body, {
      headers: {
        messageType: 'send_message',
      },
    });
    return data;
  }

  async batchRemoveDevice(device_ids: string[]) {
    const body = {
      device_ids,
    };

    const { data } = await this._instance.patch('/device/bulk/remove', body);
    return data;
  }

  async batchMaintenanceMode(device_ids: string[]) {
    const body = {
      device_ids,
    };

    const { data } = await this._instance.post('/device/bulk/maintenance_mode', body);
    return data;
  }

  async batchUpdate(device_ids: string[]) {
    const body = {
      device_ids,
    };

    const { data } = await this._instance.post('/device/bulk/sync', body);

    return data;
  }

  async batchSoundSignal(device_ids: string[]): Promise<void> {
    const body = {
      device_ids,
    };
    const { data } = await this._instance.post('/device/bulk/send_message', body, {
      headers: {
        messageType: 'find_phone',
      },
    });
    return data;
  }

  async batchShutdown(device_ids: string[]) {
    const body = {
      device_ids,
    };

    const { data } = await this._instance.post('/device/bulk/shutdow', body);

    return data;
  }

  async batchBlock(device_ids: string[], newPassword: string, define_new_pin: boolean) {
    const body = {
      device_ids,
      pin: newPassword,
      define_new_pin,
    };

    const { data } = await this._instance.post('/device/bulk/lock_device', body);
    return data;
  }

  async batchSelectAll(action: BulkActions, body: SelectAllBulkActionsBody) {
    const { data } = await this._instance.post(`/device/bulk-actions/${action}`, body);
    return data;
  }

  async getNumberOfSims() {
    const { data } = await this._instance.get('/chip/count_by_management');

    return { managed: data?.managed, pending: data.pending, total: data?.total_chips_managed };
  }

  async getSims(
    page: number,
    i18n: string,
    selectedStatus?: string,
    searchSelect?: string,
    search_value?: string,
    customFieldId?: string
  ): Promise<GetSimsResponseType> {
    const params = {
      management_type: selectedStatus || null,
      filter_by: search_value?.length === 0 ? null : searchSelect,
      search_str: search_value || null,
      custom_field_id: customFieldId || null,
    };

    const { data } = await this._instance.get(`/chip/page/${page}/basic_filter`, { params });

    const sims = data.chips.map(
      (chipData: {
        created_at: string;
        device_id: number;
        id: number;
        service_provider: string;
        sim_serial_number: string;
        status: string;
        updated_at: string;
        user_name: string;
      }) => {
        return new Sim(
          i18n,
          chipData.created_at,
          chipData.device_id,
          chipData.id,
          chipData.service_provider,
          chipData.sim_serial_number,
          chipData.status,
          chipData.updated_at,
          chipData.user_name
        );
      }
    );
    return { total: data?.total, sims };
  }

  async getPin(chip_id: string): Promise<string> {
    const { data } = await this._instance.get(`/chip/pin/${chip_id}`);

    return data.pin;
  }

  async getPuk(chip_id: string): Promise<GetSimsResponseType> {
    const { data } = await this._instance.get(`/chip/puk/${chip_id}`);

    return data.puk;
  }

  async getCustomFields() {
    const { data } = await this._instance.get('/chip/custom_field_labels');
    return data.custom_fields.map((item: { id: number; label: string }) => {
      return {
        value: item.id.toString(),
        text: item.label,
      };
    });
  }

  async uploadFile(file: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    await this._instance.post('/chip/upload_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async processFile(): Promise<void> {
    await this._instance.post('/chip/process_file');
  }

  async deleteSim(sim: Sim, sim_id: string): Promise<void> {
    await this._instance.put<void>(`/chip/${sim_id}`, {
      device_id: sim?.pulsusId === '-' ? null : Number(sim?.pulsusId),
      sim_serial_number: sim?.simSerialNumber,
      service_provider: sim?.phoneProvider,
      status: 'DELETED',
    });
  }

  async getHandleRemoteControlButton(deviceId: string) {
    const { data } = await this._instance.get(`/remote/device/${deviceId}`);
    return data;
  }

  async getChromeHandleRemoteControlView(deviceId: string) {
    try {
      const { data } = await this._instance.get(`/device/chrome/remote_session?device_id=${deviceId}`);
      return data;
    } catch (err) {
      const response = err instanceof AxiosError ? err.response : null;
      return response;
    }
  }

  async batchReregister(device_ids: string[]): Promise<void> {
    const body = {
      device_ids,
    };
    const { data } = await this._instance.post('/device/bulk/renewal', body);
    return data;
  }

  async batchOTAUpdate({ downloadUrl, buildTime, checkSum, model }) {
    const body = {
      model,
      checksum: checkSum,
      download_url: downloadUrl,
      build_time: buildTime,
    };
    await this._instance.post('/device/bulk/ota-update', body);
  }

  async getGroups(): Promise<Group[]> {
    const { data } = await this._instance.get('/group');

    return data.map((item: APIGroups) => new Group(item.name, item.work_profile_policy_name, item.id, item.group_policy_id));
  }

  async getChromeReportGroups(): Promise<ChromeReportGroup[]> {
    const { data } = await this._instance.get('/group/chrome_groups');

    return data.map((item: ChromeReportGroup) => {
      return new ChromeReportGroup(item.status, item.id, item.work_profile_policy_name, item.group_policy_id, item.name, item.pin, item.tags);
    });
  }

  async editDevice(deviceId: number, data: EditDeviceAPIBody): Promise<number> {
    const { status } = await this._instance.post(`/device/edit_device/${deviceId}`, data);

    return status;
  }

  async batchQuarantine(device_ids: string[], isActiveQuarantine: boolean) {
    const body = {
      device_ids,
      enable: isActiveQuarantine,
    };

    const { data } = await this._instance.post('/device/bulk/quarantine_mode', body);
    return data;
  }

  async getDeviceLastLocations(deviceId: string): Promise<DeviceHistory[]> {
    const { data } = await this._instance.get<DeviceHistory.Server[]>(`/device/get_last_locations/${deviceId}`);
    const lastLocations = data.map((location) => new DeviceHistory(location));
    return lastLocations;
  }

  async getApps(page: number, filterOptions?: FilterOptions, distributionParams?: DistributionParams, search_str?: string): Promise<APIApps> {
    const params = {
      $filter: filterOptions,
      search_str,
      kind: distributionParams?.kind,
      platform: distributionParams?.platform,
      'enterprise-app': distributionParams?.['enterprise-app'],
    };

    const { data } = await this._instance.get(`/application/page/${page}`, { params });

    return data;
  }

  async getNumberOfAppsPerDistribution(): Promise<APIDistributionCounter[]> {
    const { data } = await this._instance.get('/application/counters_by_distribution');

    return data;
  }

  async getTagsWithGroups(): Promise<Tag[]> {
    const { data } = await this._instance.get('/group/by_tags');

    const tagMap = {};

    data.forEach((group, index) => {
      if (group.tags.length < 1) {
        tagMap['noTag'] = {
          id: 'noTag',
          name: 'noTag',
          groups: [...(tagMap['noTag']?.groups || []), { id: group.group_id, name: group.group_name }],
        };
      } else {
        group.tags.forEach((tag) => {
          if (tag.trim() !== '') {
            if (!tagMap[tag]) {
              tagMap[tag] = {
                id: `${tag}-${randomId()}`,
                name: tag,
                groups: [],
              };
            }

            const tagObject = tagMap[tag];

            if (!tagObject.idTag) {
              tagObject.idTag = index;
            }

            tagObject.groups.push({
              id: group.group_id,
              name: group.group_name,
              selected: false,
            });
          }
        });
      }
    });

    const tags: Tag.Data[] = Object.values(tagMap);

    const adapteeData = tags.map((tag) => new Tag(tag));

    return adapteeData;
  }

  async getPermission() {
    const { data } = await this._instance.get('/permission/edit_device/');
    const hasPermission = data;
    return hasPermission;
  }

  async checkChromeOSIntegration() {
    const { data } = await this._instance.get('/chrome-os/integrations');
    return data;
  }

  async getChromeIntegrationUrl(callbackUrl: string) {
    const { data } = await this._instance.get(`/chrome-os/integrations/auth?callback_url=${callbackUrl}`);
    return data;
  }

  async postChromeOSIntegration(url: string, state: string) {
    try {
      const response = await this._instance.post('/chrome-os/integrations', { url, state });
      return response;
    } catch (err) {
      const response = err instanceof AxiosError ? err.response : null;
      return response;
    }
  }

  async getModels() {
    const { data } = await this._instance.get<string[]>('/device/models');
    return data;
  }

  async deleteChromeOSIntegration() {
    const data = await this._instance.delete('/chrome-os/integrations');
    return data;
  }

  async *fetchLocations(params: FetchLocationsBodyProps, signal: AbortSignal) {
    const body = {
      device_ids: params.deviceIds || [],
      date_filter: params.dateFilter,
      hours_range: params.hourRange,
      group_ids: params.groupIds,
      current_search_date: params.currentSearchDate,
      is_all_selected: params.isAllGroupsSelected,
    };

    const response = await fetch(`${process.env.REACT_APP_URL_PULSUS_LOKI_API}/maps`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('@jwt_token')}`,
      },
      signal,
      body: JSON.stringify(body),
    });

    const reader = response.body?.getReader() as ReadableStreamDefaultReader<Uint8Array>;
    const decoder = new TextDecoder('utf-8');

    let result = '';

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      result += decoder.decode(value);

      let endOfObjectIndex = result.indexOf('\n');
      while (endOfObjectIndex !== -1) {
        const completeObjectString = result.substring(0, endOfObjectIndex);
        result = result.substring(endOfObjectIndex + 1);

        const location: Location.Server = JSON.parse(completeObjectString);

        yield new Location(location);

        endOfObjectIndex = result.indexOf('\n');
      }
    }
  }

  async getLocationDetails(locationId: number): Promise<LocationDetails> {
    const { data } = await this._instance.get<LocationDetails.Server>(`maps/${locationId}/details`);

    return new LocationDetails(data);
  }

  async getDeviceMapsList(page: number, search?: string): Promise<Pageable<DeviceMapList[], 'devices'>> {
    const { data } = await this._instance.get<Pageable<DeviceMapList.Server[], 'devices'>>(`/devices/${page}/filter`, {
      params: { search_str: search, items_per_page: 100 },
    });

    const devicesMapList = data.devices.map((deviceMapList) => new DeviceMapList(deviceMapList));

    return { total: data.total, devices: devicesMapList };
  }

  async getGroupsList(
    page: number,
    searchValue: string,
    itemsPerPage?: number
  ): Promise<{ total: number; totalDevices: number; groups: GroupList[] }> {
    const params = {
      search_name: searchValue,
      items_per_page: itemsPerPage,
    };

    const { data } = await this._instance.get(`group/page/${page}`, { params });

    const groups = data.groups.map((group) => new GroupList(group));

    return { total: data.total, totalDevices: data.total_device_count, groups };
  }

  async getAdminInfos(): Promise<Administrator> {
    const { data } = await this._instance.get<{ administrator: Administrator.Server }>('administrators/me');

    const administrator = new Administrator(data.administrator);

    return administrator;
  }

  async getTenantsInfo(): Promise<Tenant> {
    const { data } = await this._instance.get<{ tenants: Tenant.Server }>('tenants/info');

    const tenant = new Tenant(data.tenants);

    return tenant;
  }

  async getFeatureFlags(): Promise<string[]> {
    const { data } = await this._instance.get<{ available_feature_flags: string[] }>('feature-flags');

    return data.available_feature_flags;
  }

  async getUsers(
    pageIndex: number,
    pageSize: number,
    search_type?: string,
    search_value?: string
  ): Promise<{ users: EmployeeUser[]; total: number }> {
    const params = {
      pageSize,
      search_type: search_value ? search_type : undefined,
      search_value: search_value || undefined,
    };

    const { data } = await this._instance.get<{ users: EmployeeUser.Server[]; total: number }>(`employee_users/${pageIndex}`, {
      params,
    });

    const total = data.total;
    const users = data.users.map((user) => new EmployeeUser(user));

    return { users, total };
  }

  async updateUserStatus(userId: number, status: number) {
    const { data } = await this._instance.patch<{ userId: string }>(`employee_users/${userId}/status?enabled=${status}`);

    return data;
  }

  async getUsersCount(): Promise<{ total_count: number }> {
    const { data } = await this._instance.get<{ total_count: number }>(`employee_users/count/total_users_count`);

    return data;
  }

  async getUserById(userId: string): Promise<EmployeeUser> {
    const { data } = await this._instance.get(`employee_users/user/${userId}`);

    const user = new EmployeeUser(data);

    return user;
  }

  async getChromeOSPolicies(groupId: string): Promise<ChromePolicies> {
    const { data } = await this._instance.get<ChromePolicies.Server>('/chrome-os/policies', { params: { group_id: groupId } });
    const policies = new ChromePolicies(data);

    return policies;
  }

  async updateChromeOSPolicies(body: ChromePolicies, groupId: string): Promise<void> {
    const serverdata = {
      device_policies: {
        allowBluetooth: body.allowBluetooth,
        ephemeralUsersEnabled: body.ephemeralUsersEnabled,
        deviceDisabledMessage: body.deviceDisabledMessage,
      },
      user_policies: {
        audioCaptureAllowed: body.audioCaptureAllowed,
        audioOutputAllowed: body.audioOutputAllowed,
        externalStorageDevices: body.usbConfig,
        urlBlocking: body.urlBlocking,
        timezonePolicy: false,
        videoCaptureAllowed: body.videoCaptureAllowed,
        urlAllowedList: body.urlAllowedList,
      },
      network: {
        mode: body.networkMode,
        networks: body.wifiList.map((item) => ({ ...item, device_wide: item.deviceWide })),
      },
    };

    await this._instance.post('/chrome-os/policies', serverdata, { params: { group_id: groupId } });
  }

  async getKioskModeApp(groupId: string) {
    const { data } = await this._instance.get(`/chrome-os/policies/kiosk?group_id=${groupId}`);

    return data;
  }

  async postKioskModeApp(groupId: string, kioskApp: KioskProps) {
    const { data } = await this._instance.post(`/chrome-os/policies/kiosk?group_id=${groupId}`, {
      app_id: kioskApp.app,
      app_type: kioskApp.type,
    });

    return data;
  }

  async postKioskModeAppCustomUrl(groupId: string, kioskApp: KioskProps) {
    const { data } = await this._instance.post(`/chrome-os/policies/kiosk-custom-url?group_id=${groupId}`, {
      app_id: kioskApp.app,
      app_type: kioskApp.type,
      custom_url: kioskApp.customUrl,
    });

    return data;
  }
  async createEmployeeUser(employeeUserData: EmployeeUser): Promise<string[]> {
    const { data } = await this._instance.post('employee_users', {
      firstName: employeeUserData.firstName,
      lastName: employeeUserData.lastName,
      username: employeeUserData.username,
      password: employeeUserData.password,
      email: employeeUserData.email || null,
      employeeId: employeeUserData.employeeId || null,
      workPhone: employeeUserData.workPhone || null,
      homePhone: employeeUserData.homePhone || null,
      managerName: employeeUserData.managerName || null,
      managerEmail: employeeUserData.managerEmail || null,
      managerPhoneNumber: employeeUserData.managerPhoneNumber || null,
      costCenter: employeeUserData.costCenter || null,
      employeeTitle: employeeUserData.employeeTitle || null,
      department: employeeUserData.department || null,
    });

    return data;
  }

  async deleteKioskModeApp(groupId: string) {
    const data = await this._instance.delete(`/chrome-os/policies/kiosk?group_id=${groupId}`);
    return data;
  }

  async updateEmployeeUser(employeeUserData: EmployeeUser): Promise<void> {
    await this._instance.put(`employee_users/${employeeUserData.id}`, {
      firstName: employeeUserData.firstName,
      lastName: employeeUserData.lastName,
      username: employeeUserData.username,
      password: employeeUserData.password,
      email: employeeUserData.email || null,
      employeeId: employeeUserData.employeeId || null,
      workPhone: employeeUserData.workPhone || null,
      homePhone: employeeUserData.homePhone || null,
      managerName: employeeUserData.managerName || null,
      managerEmail: employeeUserData.managerEmail || null,
      managerPhoneNumber: employeeUserData.managerPhoneNumber || null,
      costCenter: employeeUserData.costCenter || null,
      employeeTitle: employeeUserData.employeeTitle || null,
      department: employeeUserData.department || null,
    });
  }

  async importEmployeeUsers(file) {
    const data = new FormData();
    data.append('file', file);
    return this._instance.post('/employee_users/upload', data);
  }

  async getDevicesByUserId(userId: string): Promise<Device[]> {
    const { data } = await this._instance.get<{ devices: Device.Server[] }>(`/employee_users/user/${userId}/devices`);

    const list = data.devices.map((device) => new Device(device));

    return list;
  }
}

type GetSimsResponseType = {
  total: number;
  sims: Sim[];
};

type FetchLocationsBodyProps = {
  dateFilter?: string;
  deviceIds?: number[];
  groupIds?: number[];
  isAllGroupsSelected?: boolean;
  hourRange?: string;
  currentSearchDate?: boolean;
};
